import type { QueryResult } from "@apollo/client";

export interface GqData<T> {
    data: T | undefined;
    error?: Error;
    loading: boolean;
    // networkStatus: NetworkStatus;
}

/**
 * @returns query data if there is no error
 * @throws the error if there is an error
 */
export function extractData<T, TR>(query: QueryResult<T>, extract: (data: T) => TR): GqData<TR> {
    const { error, loading, data } = query;
    return {
        data: data ? extract(data) : undefined,
        error,
        loading,
        // networkStatus,
    };
}
